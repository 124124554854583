.skills__container {
  row-gap: 2rem;
  text-align: justify;
}

.skills__subtitle {
  margin-bottom: var(--mb-2);
}

.skills__text {
  margin-bottom: var(--mb-4);
}

.skills__data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-weight: var(--font-semi);
  padding: 0.5rem;
  margin-bottom: var(--mb-1);
  border-radius: 0.5rem;
  box-shadow: 0 4px 25px rgba(14, 36, 49, 0.15);
}

.skills__img {
  border-radius: 0.1rem;
}

.skills__big_img {
  border-radius: 0.5rem;
}

@media screen and (min-width: 768px) {
  .skills__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    text-align: justify;
  }
}

/* Hide the div and image when the screen size is less than 660px */
@media only screen and (max-width: 370px) {
  .skills__data {
    display: none;
  }
}
