.section {
  padding-top: 3rem;
  padding-bottom: 0;
}

.section-title {
  position: relative;
  font-size: var(--h2-font-size);
  color: var(--first-color);
  margin-top: var(--mb-2);
  margin-bottom: var(--mb-4);
  text-align: center;
}

.section-title::after {
  position: absolute;
  content: '';
  width: 64px;
  height: 0.18rem;
  left: 0;
  right: 0;
  margin: auto;
  top: 2rem;
  background-color: var(--first-color);
}

.about__container {
  row-gap: 2rem;
  text-align: justify;
}

.about__img {
  justify-self: center;
}

.about__img img {
  width: 600px;
  border-radius: 0.5rem;
}

.about__subtitle {
  margin-bottom: var(--mb-2);
}

@media screen and (min-width: 768px) {
  .section-title {
    margin-bottom: var(--mb-6);
  }

  .section-title::after {
    width: 80px;
    top: 3rem;
  }

  .about__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    text-align: justify;
  }
}

@media screen and (min-width: 1024px) {
  .bd-grid {
    margin-left: auto;
    margin-right: auto;
  }
}
