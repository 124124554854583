.btn-container {
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.filter-btn {
  background: transparent;
  border-color: transparent;
  font-size: 1rem;
  text-transform: capitalize;
  margin: 0 0.5rem;
  letter-spacing: 1px;
  padding: 0.375rem 0.75rem;
  cursor: pointer;
  transition: var(--transition);
  border-radius: var(--radius);
}
.filter-btn:hover {
  background: var(--first-color);
  color: var(--clr-white);
}

@media screen and (min-width: 768px) {
  .btn-container {
    /* margin-bottom: 4rem; */
    display: flex;
    justify-content: center;
  }
}
