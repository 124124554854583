/*===== HOME =====*/
.home {
  height: calc(100vh - 3rem);
  row-gap: 1rem;
}

.home__data {
  align-self: center;
}

.home__title {
  font-size: var(--big-font-size);
  margin-bottom: var(--mb-5);
}

.home__title-color {
  color: var(--first-color);
}

.home__social {
  display: flex;
  flex-direction: column;
}

.home__social-icon {
  width: max-content;
  margin-bottom: var(--mb-2);
  font-size: 1.5rem;
  color: var(--second-color);
}

.home__img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 500px;
  max-width: 80%;
  height: auto;
}

/*BUTTONS*/
.button {
  display: inline-block;
  background-color: var(--first-color);
  color: #fff;
  padding: 0.5rem 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: var(--font-semi);
  border-radius: 0.5rem;
}
.button:hover {
  box-shadow: 0 10px 36px rgba(0, 0, 0, 0.15);
}

@media screen and (min-width: 768px) {
  .home {
    height: 100vh;
  }

  .home__data {
    align-self: flex-end;
  }

  .home__social {
    padding-top: 0;
    padding-bottom: 2.5rem;
    flex-direction: row;
    align-self: flex-end;
  }

  .home__social-icon {
    margin-bottom: 0;
    margin-right: var(--mb-4);
  }

  .home__img {
    width: 457;
    bottom: 15%;
    max-width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 1024px) {
  .bd-grid {
    margin-left: auto;
    margin-right: auto;
  }
  .home__img {
    right: 10%;
    max-width: 100%;
    height: auto;
  }
}

/* Hide the div and image when the screen size is less than 660px */
@media only screen and (max-width: 370px) {
  .home__img {
    width: 70%;
  }
}

/* Hide the div and image when the screen size is less than 660px */
@media only screen and (max-width: 376px) {
  .home__img {
    width: 70%;
  }
}

/* Hide the div and image when the screen size is less than 280px */
@media only screen and (max-width: 280px) {
  .home__img {
    display: none;
  }
}
