.work__container {
  row-gap: 2rem;
}

@media screen and (min-width: 768px) {
  .work__container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 2rem;
  }
}
