/* ===== FOOTER =====*/
.footer {
  background-color: var(--second-color);
  color: #fff;
  text-align: center;
  font-weight: var(--font-semi);
  padding: 0.2rem 0;
}

.footer__title {
  font-size: 0;
  margin-bottom: var(--mb-1);
}
.footer__social {
  margin-bottom: 0;
}

.footer__icon {
  font-size: 1.5rem;
  color: #fff;
  margin: 0 var(--mb-2);
}
