.work__img {
  box-shadow: 0 4px 25px rgba(14, 36, 49, 0.15);
  border-radius: 0.5rem;
  overflow: hidden;
}

.work__img img {
  transition: 1s;
  cursor: pointer;
}

.work__img img:hover {
  transform: scale(1.1);
}

.card_info {
  margin: 1rem;
}

.home__social {
  display: flex;
  flex-direction: column;
}
.home__social-icon {
  width: max-content;
  margin-bottom: var(--mb-2);
  font-size: 1.5rem;
  color: var(--second-color);
}
.home__social-icon:hover {
  color: var(--first-color);
}

.button_work {
  display: inline-block;
  background-color: var(--first-color);
  color: #fff;
  padding: 0.25rem;
  font-weight: var(--font-semi);
  border-radius: 0.5rem;
}
.button_work:hover {
  box-shadow: 0 10px 36px rgba(0, 0, 0, 0.15);
}

@media screen and (min-width: 768px) {
  .photo {
    height: 175px;
  }
}

@media screen and (min-width: 1024px) {
  .home__social {
    padding-top: 0;
    padding-bottom: 2.5rem;
    flex-direction: row;
    align-self: flex-end;
  }

  /* .home__social-icon {
    margin-bottom: 0;
    margin-right: 0;
  } */
}

.photo {
  object-fit: cover;
  height: 200px;
  width: 100%;
  border: 0.25rem solid var(--first-color);
  border-radius: var(--radius);
  display: block;
}

.item-info header {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px dotted var(--clr-grey-5);
}
.item-info h4 {
  margin-bottom: 0.5rem;
}

/* Modall CSS start here */

.modal-content {
  max-width: 75%;
  max-height: 75%;
  position: fixed;
  margin-left: 8%;
}

/* Modal image */
.ReactModal__Overlay {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.ReactModal__Content {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 80vw;
  max-height: 80vh;

  overflow: auto;
  border-radius: 10px;
  outline: none;
  padding: 20px;
}

.ReactModal__Content:focus {
  outline: none;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

.ReactModal__Overlay.ReactModal__Overlay--before-close {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open .ReactModal__Content {
  transform: scale(0.8);
  transition: transform 250ms ease-in-out;
}

.ReactModal__Overlay.ReactModal__Overlay--before-close .ReactModal__Content {
  transform: scale(0.5);
  transition: transform 250ms ease-in-out;
}

.ReactModal__Overlay.ReactModal__Overlay--before-close .ReactModal__Content,
.ReactModal__Overlay.ReactModal__Overlay--before-close .ReactModal__Content {
  pointer-events: none;
}
