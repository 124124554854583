@media screen and (min-width: 768px) {
  .contact__container {
    justify-items: center;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;
  }
  .contact__form {
    width: 360px;
    row-gap: 2rem;
  }
}

/* .contact__container iframe {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.contact__container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */

/* ===== CONTACT =====*/
.contact__input {
  width: 100%;
  font-size: var(--normal-font-size);
  font-weight: var(--font-semi);
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1.5px solid var(--second-color);
  outline: none;
  margin-bottom: var(--mb-4);
}
.contact__button {
  display: block;
  border: none;
  outline: none;
  font-size: var(--normal-font-size);
  cursor: pointer;
  margin-left: auto;
}
